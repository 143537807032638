/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import {
  Row, Col, Form, Button,
} from 'react-bootstrap';

import {
  showService,
  showDirectService,
  updateService,
  updateDirectService,
  showDirectDocUrl,
  showDocUrl,
  reqMsType,
} from '../../services/api';
import Menu from '../../components/Menu/Menu';
import AppNavbar from '../../components/AppNavbar/AppNavbar';

import './styles.css';

export default class EditService extends Component {
  isSync = window.location.href.includes('/sync/')

  state = {
    route: this.isSync ? 'sync' : 'async',
    title: '',
    service_id: '',
    response_time: '',
    url_post: '',
    url_get: '',
    url: '',
    doc_url: '',
    actived: false,
    showPublic: false,
    saveInputs: false,
    billing: false,
    msTypes: null,
    serviceType: {
      type: '',
      division: '',
      source: '',
      state: '',
      city: '',
      to_entities: [],
    },
    isDivisionState: false,
  };

  async componentDidMount() {
    try {
      const response = await reqMsType();

      this.setState({
        msTypes: response.data.ms_type,
      });
    } catch (err) {
      if (err.response) {
        alert(err.response.data.msg_errors[0].msg);
      } else if (err.request) {
        alert(err.request);
      } else {
        alert(err.message);
      }
    }

    try {
      const responseMicroservice = await this.defineShowFunction()(this.props.match.params.service_id);

      this.setStatesFromResponse(responseMicroservice);
    } catch (err) {
      if (err.response) {
        alert(err.response.data.msg_errors[0].msg);
      } else if (err.request) {
        alert(err.request);
      } else {
        alert(err.message);
      }
      this.props.history.push(`/${this.state.route}/list`);
    }

    try {
      const responseDocUrl = await this.defineshowDocUrlFunction()(this.props.match.params.service_id);

      this.setState({
        doc_url: responseDocUrl.data.doc_url,
      });
    } catch (err) {
      alert('doc_url não encontrado!');
    }
  }

  defineShowFunction = () => {
    if (this.isSync) return showDirectService;

    return showService;
  }

  defineUpdateFunction = () => {
    if (this.isSync) return updateDirectService;

    return updateService;
  }

  defineshowDocUrlFunction = () => {
    if (this.isSync) return showDirectDocUrl;

    return showDocUrl;
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await this.defineUpdateFunction()(this.defineParams());

      alert(`${this.state.service_id} alterado com sucesso.`);
    } catch (err) {
      if (err.response) {
        alert(err.response.data.msg_errors[0].msg);
      } else if (err.request) {
        alert(err.request);
      } else {
        alert(err.message);
      }
    }
  };

  defineParams = () => {
    const hash = {
      service_id: this.state.service_id,
      name: this.state.title,
      response_time: this.state.response_time,
      doc_url: this.state.doc_url,
      actived: this.state.actived,
      show_public: this.state.showPublic,
      service_type: this.state.serviceType,
    };

    if (this.isSync) {
      hash.url = this.state.url;
      hash.save_inputs = this.state.saveInputs;
      hash.billing = this.state.billing;
    } else {
      hash.url_post = this.state.url_post;
      hash.url_get = this.state.url_get;
    }

    return hash;
  }

  setStatesFromResponse = (responseMicroservice) => {
    const hash = {
      title: responseMicroservice.data.name,
      service_id: responseMicroservice.data.mid,
      response_time: responseMicroservice.data.response_time,
      actived: responseMicroservice.data.actived,
      showPublic: responseMicroservice.data.show_public,
      serviceType: {
        type:
          responseMicroservice.data.service_type !== null
          && responseMicroservice.data.service_type.type !== null
            ? responseMicroservice.data.service_type.type
            : '',
        division:
          responseMicroservice.data.service_type !== null
          && responseMicroservice.data.service_type.division !== null
            ? responseMicroservice.data.service_type.division
            : '',
        source:
          responseMicroservice.data.service_type !== null
          && responseMicroservice.data.service_type.source !== null
            ? responseMicroservice.data.service_type.source
            : '',
        state:
          responseMicroservice.data.service_type !== null
          && responseMicroservice.data.service_type.state !== null
            ? responseMicroservice.data.service_type.state
            : '',
        city:
          responseMicroservice.data.service_type !== null
          && responseMicroservice.data.service_type.city !== null
            ? responseMicroservice.data.service_type.city
            : '',
        to_entities:
          responseMicroservice.data.service_type !== null
          && responseMicroservice.data.service_type.to_entities !== null
            ? responseMicroservice.data.service_type.to_entities
            : [],
      },
      isDivisionState:
        responseMicroservice.data.service_type !== null
        && responseMicroservice.data.service_type.division === 'STATE',
    };

    if (this.isSync) {
      hash.url = responseMicroservice.data.url;
      hash.saveInputs = responseMicroservice.data.save_inputs;
      hash.billing = responseMicroservice.data.billing;
    } else {
      hash.url_post = responseMicroservice.data.url_post;
      hash.url_get = responseMicroservice.data.url_get;
    }

    this.setState(hash);
  }

  renderInputUrl = () => {
    if (this.state.billing) return null;

    if (this.isSync) {
      return (
        <Form.Group controlId="formUrl">
          <Form.Label>url</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="url"
            value={this.state.url}
            onChange={(e) => {
              this.setState({ url: e.target.value });
            }}
          />
        </Form.Group>
      );
    }

    return null;
  }

  renderInputUrlPostGet = () => {
    if (this.isSync) return null;

    return (
      <>
        <Form.Group controlId="formUrlPost">
          <Form.Label>url POST</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="url_post"
            value={this.state.url_post}
            onChange={(e) => {
              this.setState({ url_post: e.target.value });
            }}
          />
        </Form.Group>

        <Form.Group controlId="formUrlGet">
          <Form.Label>url GET</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="url_get"
            value={this.state.url_get}
            onChange={(e) => {
              this.setState({ url_get: e.target.value });
            }}
          />
        </Form.Group>
      </>
    );
  }

  renderCheckboxSaveInputs = () => {
    if (this.isSync) {
      return (
        <Form.Group controlId="formSaveInputs" style={{ marginTop: '7px' }}>
          <Form.Check
            type="checkbox"
            label="Salvar inputs no BD (desmarque se os inputs são grandes. Ex: base64)"
            checked={this.state.saveInputs}
            onChange={() => {
              // eslint-disable-next-line react/no-access-state-in-setstate
              this.setState({ saveInputs: !this.state.saveInputs });
            }}
          />
        </Form.Group>
      );
    }

    return null;
  }

  renderCheckboxBilling = () => {
    if (this.isSync) {
      return (
        <Form.Group controlId="formBilling" style={{ marginTop: '7px' }}>
          <Form.Check
            type="checkbox"
            label="O serviço do tipo billing (contagem de requisições). O service_id deve terminar com '_billing'"
            checked={this.state.billing}
            onChange={() => {
              // eslint-disable-next-line react/no-access-state-in-setstate
              this.setState({ billing: !this.state.billing });
            }}
          />
        </Form.Group>
      );
    }

    return null;
  }

  render() {
    return (
      <Fragment>
        <AppNavbar />

        <Row>
          <Col sm={2}>
            <Menu />
          </Col>
          <Col sm={8}>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group controlId="formServiceTitle">
                <Form.Label>Título</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Título do serviço"
                  value={this.state.title}
                  onChange={(e) => {
                    this.setState({ title: e.target.value });
                  }}
                />
              </Form.Group>

              <Form.Group controlId="formServiceId">
                <Form.Label>Id do Serviço</Form.Label>
                <Form.Control
                  readOnly
                  type="text"
                  placeholder="service_id"
                  value={this.state.service_id}
                  onChange={(e) => {
                    this.setState({ service_id: e.target.value });
                  }}
                />
                <Form.Text className="text-muted">Aceita apenas a-z 0-9 e _</Form.Text>
              </Form.Group>

              <Form.Group controlId="formResponseTime">
                <Form.Label>Tempo médio de resposta (em seg.)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="response_time"
                  value={this.state.response_time}
                  onChange={(e) => {
                    this.setState({ response_time: e.target.value });
                  }}
                />
                <Form.Text className="text-muted">Aceita apenas números de 1 a 432000</Form.Text>
              </Form.Group>

              {this.renderInputUrl()}

              {this.renderInputUrlPostGet()}

              <Form.Group controlId="docUrl">
                <Form.Label>doc URL</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="url da documentação"
                  value={this.state.doc_url}
                  onChange={(e) => {
                    this.setState({ doc_url: e.target.value });
                  }}
                />
              </Form.Group>

              <Form.Group controlId="formActived">
                <Form.Check
                  type="checkbox"
                  label="Ativado"
                  checked={this.state.actived}
                  onChange={() => {
                    // eslint-disable-next-line react/no-access-state-in-setstate
                    this.setState({ actived: !this.state.actived });
                  }}
                />
              </Form.Group>

              <Form.Group controlId="formShowPublic">
                <Form.Check
                  type="checkbox"
                  label="Mostrar em Público"
                  checked={this.state.showPublic}
                  onChange={() => {
                    // eslint-disable-next-line react/no-access-state-in-setstate
                    this.setState({ showPublic: !this.state.showPublic });
                  }}
                />
              </Form.Group>

              {this.renderCheckboxSaveInputs()}

              {this.renderCheckboxBilling()}

              <fieldset style={{ border: '1px groove black', padding: '7px' }}>
                <legend style={{ width: 'inherit', padding: '0 10px', borderBottom: 'none' }}>
                  Service Type
                </legend>
                <Row>
                  <Col md={3}>
                    <Form.Group controlId="msTypeType">
                      <Form.Label>Tipo</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          e.persist();
                          this.setState(prevState => ({
                            serviceType: { ...prevState.serviceType, type: e.target.value },
                          }));
                        }}
                        value={this.state.serviceType.type}
                      >
                        <option />
                        {this.state.msTypes != null
                          ? this.state.msTypes.type.map((element, i) => (
                            <option key={i.toString()}>{element}</option>
                          ))
                          : ''}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={1} />
                  <Col md={3}>
                    <Form.Group controlId="msTypeDivision">
                      <Form.Label>Divisão</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          e.persist();
                          this.setState(prevState => ({
                            serviceType: {
                              ...prevState.serviceType,
                              division: e.target.value,
                              state: e.target.value === 'STATE' ? prevState.serviceType.state : '',
                              city: e.target.value === 'STATE' ? prevState.serviceType.city : '',
                            },
                            isDivisionState: e.target.value === 'STATE',
                          }));
                        }}
                        value={this.state.serviceType.division}
                      >
                        <option />
                        {this.state.msTypes != null
                          ? this.state.msTypes.division.map((element, i) => (
                            <option key={i.toString()}>{element}</option>
                          ))
                          : ''}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={1} />
                  <Col md={3}>
                    <Form.Group controlId="msTypeSource">
                      <Form.Label>Fonte de dados</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          e.persist();
                          this.setState(prevState => ({
                            serviceType: { ...prevState.serviceType, source: e.target.value },
                          }));
                        }}
                        value={this.state.serviceType.source}
                      >
                        <option />
                        {this.state.msTypes != null
                          ? this.state.msTypes.source.map((element, i) => (
                            <option key={i.toString()}>{element}</option>
                          ))
                          : ''}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <Form.Group controlId="msTypeState">
                      <Form.Label>Estado</Form.Label>
                      <Form.Control
                        disabled={!this.state.isDivisionState}
                        as="select"
                        onChange={(e) => {
                          e.persist();
                          this.setState(prevState => ({
                            serviceType: { ...prevState.serviceType, state: e.target.value },
                          }));
                        }}
                        value={this.state.serviceType.state}
                      >
                        <option />
                        {this.state.msTypes != null
                          ? this.state.msTypes.state.map((element, i) => (
                            <option key={i.toString()}>{element}</option>
                          ))
                          : ''}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={10}>
                    <Form.Group controlId="msTypeCity">
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        disabled={!this.state.isDivisionState}
                        type="text"
                        placeholder="Cidade"
                        value={this.state.serviceType.city}
                        onChange={(e) => {
                          e.persist();
                          this.setState(prevState => ({
                            serviceType: {
                              ...prevState.serviceType,
                              city: e.target.value.toUpperCase(),
                            },
                          }));
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group>
                  <Form.Label>Entidades</Form.Label>
                  <Row>
                    {this.state.msTypes != null
                      ? this.state.msTypes.to_entity.map((element, i) => (
                        <Col key={i.toString()} md={3}>
                          <Form.Check
                            id={`checkbox${element}`}
                            htmlFor={`checkbox${element}`}
                            value={element}
                            type="checkbox"
                            checked={this.state.serviceType.to_entities.includes(element)}
                            label={element}
                            style={{ margin: '5px' }}
                            onChange={(e) => {
                              if (this.state.serviceType.to_entities.includes(e.target.value)) {
                                const toEntitiesCopy = [...this.state.serviceType.to_entities];
                                toEntitiesCopy.splice(
                                  this.state.serviceType.to_entities.indexOf(e.target.value),
                                  1,
                                );
                                this.setState(prevState => ({
                                  serviceType: {
                                    ...prevState.serviceType,
                                    to_entities: toEntitiesCopy,
                                  },
                                }));
                              } else {
                                this.setState(prevState => ({
                                  serviceType: {
                                    ...prevState.serviceType,
                                    to_entities: [...prevState.serviceType.to_entities, element],
                                  },
                                }));
                              }
                            }}
                          />
                        </Col>
                      ))
                      : ''}
                  </Row>
                </Form.Group>
              </fieldset>

              <Button variant="primary" type="submit" style={{ marginTop: '7px' }}>
                Alterar
              </Button>
            </Form>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
