/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import {
  Form, Button, Row, Col,
} from 'react-bootstrap';

import './styles.css';

import { signin } from '../../services/api';
import { login, isAuthenticated } from '../../services/auth';

export default class Home extends Component {
  state = {
    username: '',
    password: '',
    error: '',
  };

  componentDidMount() {
    if (isAuthenticated()) {
      this.props.history.push('/home');
    }
  }

  handleSignIn = async (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    if (!username || !password) {
      this.setState({ error: 'Preencha username e password para continuar!' });
    } else {
      try {
        const response = await signin({ username, password });
        login(response.data.token);
        this.props.history.push('/home');
      } catch (err) {
        console.log(err);
        this.setState({
          error: 'Houve um problema com o login, verifique suas credenciais.',
        });
      }
    }
  };

  render() {
    return (
      <div className="container">
        <Row className="justify-content-md-center">
          <Col sm={6}>
            <Form onSubmit={this.handleSignIn}>
              {this.state.error && <p>{this.state.error}</p>}
              <Form.Group controlId="formUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter username"
                  value={this.state.username}
                  onChange={e => this.setState({ username: e.target.value })}
                />
              </Form.Group>

              <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={e => this.setState({ password: e.target.value })}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}
