const jwtDecode = require('jwt-decode');
const moment = require('moment');

const isExpired = (decodedVal) => {
  if (moment() > moment.unix(decodedVal.exp)) return true;

  return false;
};
export const TOKEN_KEY = 'User-Token';
export const isAuthenticated = () => {
  if (localStorage.getItem(TOKEN_KEY) !== null) {
    try {
      return !isExpired(jwtDecode(localStorage.getItem(TOKEN_KEY)));
    } catch (err) {
      return false;
    }
  } else {
    return false;
  }
};
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const currentUserName = () => {
  if (isAuthenticated()) {
    const decoded = jwtDecode(localStorage.getItem(TOKEN_KEY));
    return decoded.user_name;
  }
  return 'Admin';
};
export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};
