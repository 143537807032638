/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component, Fragment } from 'react';
import { Spinner } from 'react-bootstrap';

import { signout } from '../../services/api';
import { logout } from '../../services/auth';

import AppNavbar from '../../components/AppNavbar/AppNavbar';
import './styles.css';

export default class Signout extends Component {
  async componentDidMount() {
    try {
      await signout();
      logout();
      this.props.history.push('/');
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
        alert(err.response.data.msg_errors[0].msg);
      } else if (err.request) {
        alert(err.request);
      } else {
        alert(err.message);
      }
      logout();
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <Fragment>
        <AppNavbar />
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Fragment>
    );
  }
}
