import React from 'react';
import { Nav } from 'react-bootstrap';

import './styles.css';

const Menu = () => (
  <Nav defaultActiveKey="/home" className="flex-column">
    <Nav.Link href="/home">Home</Nav.Link>
    <div className="nav-container">
      <p>Serviços Assíncronos</p>
      <Nav.Link href="/async/add">Adicionar</Nav.Link>
      <Nav.Link href="/async/list">Listar</Nav.Link>
    </div>

    <br />
    <div className="nav-container">
      <p>Serviços Síncronos</p>
      <Nav.Link href="/sync/add">Adicionar</Nav.Link>
      <Nav.Link href="/sync/list">Listar</Nav.Link>
    </div>
  </Nav>
);

export default Menu;
