/* eslint-disable react/prop-types */
import React from 'react';
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';

import { isAuthenticated } from './services/auth';

import SignIn from './pages/SignIn/index';
import Home from './pages/Home/index';
import AddService from './pages/AddService/index';
import ListService from './pages/ListService/index';
import EditService from './pages/EditService/index';
import Signout from './pages/Signout/index';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (isAuthenticated() ? (
      <Component {...props} />
    ) : (
      <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    ))
    }
  />
);

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact component={SignIn} />
      <PrivateRoute path="/home" exact component={Home} />
      <PrivateRoute path="/async/add" exact component={AddService} />
      <PrivateRoute path="/sync/add" exact component={AddService} />
      <PrivateRoute path="/async/list" exact component={ListService} />
      <PrivateRoute path="/sync/list" exact component={ListService} />
      <PrivateRoute path="/async/edit/:service_id" component={EditService} />
      <PrivateRoute path="/sync/edit/:service_id" component={EditService} />
      <PrivateRoute path="/logout" component={Signout} />
      <Route path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
