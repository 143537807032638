import axios from 'axios';
import { getToken } from './auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_ORCH_ADMIN_HOST,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  const newConfig = config;

  if (token) {
    newConfig.headers.access_token = token;
  }

  const apiKey = process.env.REACT_APP_ORCH_ADMIN_KEY;
  if (apiKey) {
    newConfig.headers['x-api-key'] = apiKey;
  }

  return newConfig;
});

const signin = async authParams => api.post('/users/auth', authParams);

const signout = async () => api.get('/users/logout');

const addService = async serviceData => api.post('/admin/microservice', serviceData);
const addDirectService = async serviceData => api.post('/admin/direct_microservice', serviceData);

const listService = async (query = null) => {
  let urlFilters = '';

  if (query !== null) {
    urlFilters = query;
  }

  return api.get(`/admin/microservice/index${urlFilters}`);
};

const listDirectService = async (query = null) => {
  let urlFilters = '';

  if (query !== null) {
    urlFilters = query;
  }

  return api.get(`/admin/direct_microservice/index${urlFilters}`);
};

const reqMsType = () => api.get('/admin/mstype');

const showService = async serviceId => api.get(`/admin/microservice/show?service_id=${serviceId}`);
const showDirectService = async serviceId => api.get(`/admin/direct_microservice/show?service_id=${serviceId}`);

const showDocUrl = async serviceId => api.get(`/admin/documentationapis/show?service_id=${serviceId}`);
const showDirectDocUrl = async serviceId => api.get(`/admin/direct_documentationapis/show?service_id=${serviceId}`);

const updateService = async serviceData => api.put('/admin/microservice', serviceData);
const updateDirectService = async serviceData => api.put('/admin/direct_microservice', serviceData);

const deleteService = async serviceId => api.delete(`/admin/microservice?service_id=${serviceId}`);
const deleteDirectService = async serviceId => api.delete(`/admin/direct_microservice?service_id=${serviceId}`);

export default api;
export {
  listService,
  listDirectService,
  showService,
  showDirectService,
  updateService,
  updateDirectService,
  deleteService,
  deleteDirectService,
  addService,
  addDirectService,
  showDocUrl,
  showDirectDocUrl,
  signin,
  signout,
  reqMsType,
};
