/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from 'react-bootstrap';

const ButtonLink = props => (
  <Button href={props.href} variant={props.variant} size={props.size}>
    {props.text}
  </Button>
);

export default ButtonLink;
