import React from 'react';
import { Navbar, Button } from 'react-bootstrap';
import { FaUserAlt, FaSignOutAlt } from 'react-icons/fa';

import { isAuthenticated, currentUserName } from '../../services/auth';

const LogoutBtn = () => {
  if (isAuthenticated()) {
    return (
      <Navbar.Collapse className="justify-content-end">
        <Button href="/logout" variant="primary">
          Logout
          <FaSignOutAlt />
        </Button>
      </Navbar.Collapse>
    );
  }
  return null;
};

const AppNavbar = () => (
  <Navbar bg="light" variant="light">
    <Navbar.Brand href="/home">
      {currentUserName()}
      <FaUserAlt />
    </Navbar.Brand>
    <LogoutBtn />
  </Navbar>
);

export default AppNavbar;
