import React, { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';

import Menu from '../../components/Menu/Menu';
import AppNavbar from '../../components/AppNavbar/AppNavbar';

import './styles.css';

const Home = () => (
  <Fragment>
    <AppNavbar />

    <Row>
      <Col sm={2}>
        <Menu />
      </Col>
      <Col sm={10} />
    </Row>
  </Fragment>
);

export default Home;
