/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-alert */
/* eslint-disable no-console */
import React, { Component, Fragment } from 'react';
import moment from 'moment';
import {
  Table,
  Row,
  Col,
  Button,
  Form,
  FormControl,
  Pagination,
  FormCheck,
  FormLabel,
  FormGroup,
} from 'react-bootstrap';

import {
  listService,
  listDirectService,
  deleteService,
  deleteDirectService,
  reqMsType,
} from '../../services/api';
import Menu from '../../components/Menu/Menu';
import AppNavbar from '../../components/AppNavbar/AppNavbar';
import ButtonLink from '../../components/ButtonLink/ButtonLInk';

import './styles.css';

const ServiceType = ({ serviceType }) => {
  if (serviceType != null) {
    return (
      <span style={{ fontSize: '10px' }}>
        <b>à entidade: </b>
        {' '}
        {serviceType.to_entities !== null ? serviceType.to_entities.join(', ') : ''}
        {' '}
        <b>tipo: </b>
        {' '}
        {serviceType.type}
        {' '}
        <b>font de dados: </b>
        {' '}
        {serviceType.source}
        {' '}
        <b>divisão: </b>
        {' '}
        {serviceType.division}
        {' '}
        <b>estado: </b>
        {' '}
        {serviceType.state}
        {' '}
        <b>cidade: </b>
        {' '}
        {serviceType.city}
      </span>
    );
  }
  return '';
};

export default class ListService extends Component {
  isSync = window.location.href.includes('/sync/');

  state = {
    route: this.isSync ? 'sync' : 'async',
    data: [],
    msTypes: null,
    radioActived: 'all',
    radioShowPublic: 'all',
    filterText: '',
    filterUrlPostGet: '',
    serviceType: {
      type: '',
      division: '',
      source: '',
      state: '',
      city: '',
      to_entities: [],
    },
  };

  queryFilter = null;

  itensPerPage = 25;

  pageCurrent = 1;

  totalPages = 1;

  paginationItems = [];

  async componentDidMount() {
    const url = window.location.href;
    const queryFilters = url.split('?');
    if (queryFilters.length > 1) {
      // eslint-disable-next-line prefer-destructuring
      this.queryFilter = `?${queryFilters[1]}`;

      this.setInitialValue(queryFilters[1]);
    }
    try {
      const response = await this.defineListFunction()(this.queryFilter);
      this.itensPerPage = response.headers['x-per-page'];
      const itensTotal = response.headers['x-total'];
      const aditionalPage = itensTotal % this.itensPerPage > 0 ? 1 : 0;
      this.totalPages = Math.floor(itensTotal / this.itensPerPage) + aditionalPage;
      this.pagination();

      this.setState({
        data: response.data,
      });
    } catch (err) {
      if (err.response) {
        alert(err.response.data.msg_errors[0].msg);
      } else if (err.request) {
        alert(err.request);
      } else {
        alert(err.message);
      }
    }

    try {
      const response = await reqMsType({});

      this.setState({
        msTypes: response.data.ms_type,
      });
    } catch (err) {
      if (err.response) {
        alert(err.response.data.msg_errors[0].msg);
      } else if (err.request) {
        alert(err.request);
      } else {
        alert(err.message);
      }
    }
  }

  async onSearchServicesClick() {
    let queryUrl = '';

    this.state.serviceType.to_entities.forEach((element) => {
      if (element.length > 0) {
        queryUrl += `&filter_to_entities[]=${element}`;
      }
    });
    if (this.state.serviceType.type !== null && this.state.serviceType.type.length > 0) {
      queryUrl += `&filter_type=${this.state.serviceType.type}`;
    }
    if (this.state.serviceType.city !== null && this.state.serviceType.city.length > 0) {
      queryUrl += `&filter_city=${this.state.serviceType.city}`;
    }
    if (this.state.serviceType.division !== null && this.state.serviceType.division.length > 0) {
      queryUrl += `&filter_division=${this.state.serviceType.division}`;
    }
    if (this.state.serviceType.state !== null && this.state.serviceType.state.length > 0) {
      queryUrl += `&filter_state=${this.state.serviceType.state}`;
    }
    if (this.state.serviceType.source !== null && this.state.serviceType.source.length > 0) {
      queryUrl += `&filter_source=${this.state.serviceType.source}`;
    }
    if (this.state.filterText !== null && this.state.filterText.length > 0) {
      queryUrl += `&filter_text=${this.state.filterText}`;
    }
    if (this.state.filterUrlPostGet !== null && this.state.filterUrlPostGet.length > 0) {
      queryUrl += `&filter_post_get=${this.state.filterUrlPostGet}`;
    }
    if (
      this.state.radioActived !== null
      && this.state.radioActived !== 'all'
      && this.state.radioActived.length > 0
    ) {
      queryUrl += `&filter_actived=${this.state.radioActived}`;
    }
    if (
      this.state.radioShowPublic !== null
      && this.state.radioShowPublic !== 'all'
      && this.state.radioShowPublic.length > 0
    ) {
      queryUrl += `&filter_show_public=${this.state.radioShowPublic}`;
    }
    if (queryUrl !== '') {
      queryUrl = `?${queryUrl.slice(1, queryUrl.length)}`;
    }
    this.queryFilter = queryUrl;
    window.history.pushState('', '', `/${this.state.route}/list${this.queryFilter}`);

    this.pageCurrent = 1;
    try {
      this.paginationItems = [];
      const response = await this.defineListFunction()(this.queryFilter);

      this.itensPerPage = response.headers['x-per-page'];
      const itensTotal = response.headers['x-total'];
      const aditionalPage = itensTotal % this.itensPerPage > 0 ? 1 : 0;

      this.totalPages = Math.floor(itensTotal / this.itensPerPage) + aditionalPage;

      this.pagination();
      this.setState({
        data: response.data,
      });
    } catch (err) {
      if (err.response) {
        alert(err.response.data.msg_errors[0].msg);
      } else if (err.request) {
        alert(err.request);
      } else {
        alert(err.message);
      }
    }
  }

  setInitialValue(query) {
    let filterText = '';
    let filterUrlPostGet = '';
    let filterActived = 'all';
    let filterShowPublic = 'all';
    let serviceTypeType = '';
    let serviceTypeDivision = '';
    let serviceTypeSource = '';
    let serviceTypeState = '';
    let serviceTypeCity = '';
    const serviceTypeToEntities = [];
    let pageCurrent = 1;

    if (query.includes('filter_text=')) {
      const aux = query.split('filter_text=')[1];
      const index = aux.indexOf('&');
      filterText = index > 0 ? aux.slice(0, index) : aux;
    }

    if (query.includes('filter_post_get=')) {
      const aux = query.split('filter_post_get=')[1];
      const index = aux.indexOf('&');
      filterUrlPostGet = index > 0 ? aux.slice(0, index) : aux;
    }

    if (query.includes('filter_actived=')) {
      const aux = query.split('filter_actived=')[1];
      const index = aux.indexOf('&');
      filterActived = index > 0 ? aux.slice(0, index) : aux;
    }

    if (query.includes('filter_show_public=')) {
      const aux = query.split('filter_show_public=')[1];
      const index = aux.indexOf('&');
      filterShowPublic = index > 0 ? aux.slice(0, index) : aux;
    }

    if (query.includes('filter_type=')) {
      const aux = query.split('filter_type=')[1];
      const index = aux.indexOf('&');
      serviceTypeType = index > 0 ? aux.slice(0, index) : aux;
    }

    if (query.includes('filter_division=')) {
      const aux = query.split('filter_division=')[1];
      const index = aux.indexOf('&');
      serviceTypeDivision = index > 0 ? aux.slice(0, index) : aux;
    }

    if (query.includes('filter_source=')) {
      const aux = query.split('filter_source=')[1];
      const index = aux.indexOf('&');
      serviceTypeSource = index > 0 ? aux.slice(0, index) : aux;
    }

    if (query.includes('filter_state=')) {
      const aux = query.split('filter_state=')[1];
      const index = aux.indexOf('&');
      serviceTypeState = index > 0 ? aux.slice(0, index) : aux;
    }

    if (query.includes('filter_city=')) {
      const aux = query.split('filter_city=')[1];
      const index = aux.indexOf('&');
      serviceTypeCity = index > 0 ? aux.slice(0, index) : aux;
    }

    if (query.includes('filter_to_entities[]=')) {
      query.split('filter_to_entities[]=').forEach((element, i) => {
        if (i === 0) {
          return;
        }
        const indexAux = element.indexOf('&');
        if (indexAux > 0) {
          serviceTypeToEntities.push(element.slice(0, indexAux));
        } else {
          serviceTypeToEntities.push(element);
        }
      });
    }

    if (query.includes('page=')) {
      const aux = query.split('page=')[1];
      const index = aux.indexOf('&');
      pageCurrent = index > 0 ? aux.slice(0, index) : aux;
      pageCurrent = Number.isInteger(parseInt(pageCurrent)) ? parseInt(pageCurrent) : 1;
    }

    this.setState({
      radioActived: filterActived,
      radioShowPublic: filterShowPublic,
      filterText,
      filterUrlPostGet,
      serviceType: {
        type: serviceTypeType,
        division: serviceTypeDivision,
        source: serviceTypeSource,
        state: serviceTypeState,
        city: serviceTypeCity,
        to_entities: serviceTypeToEntities,
      },
    });
    this.pageCurrent = pageCurrent;
  }

  defineListFunction = () => {
    if (this.isSync) return listDirectService;

    return listService;
  }

  defineDeleteFunction = () => {
    if (this.isSync) return deleteDirectService;

    return deleteService;
  }

  resetFilters = () => {
    this.myFormRef.reset();
  };

  async deleteItem(mid) {
    try {
      await this.defineDeleteFunction()(mid);

      alert(`${mid} deletado com sucesso.`);
      window.location.reload();
    } catch (err) {
      if (err.response) {
        alert(err.response.data.msg_errors[0].msg);
      } else if (err.request) {
        alert(err.request);
      } else {
        alert(err.message);
      }
    }
  }

  pagination() {
    for (let num = 1; num <= this.totalPages; num += 1) {
      this.paginationItems.push(
        <Pagination.Item
          key={num}
          onClick={async () => {
            this.paginationItems = [];
            this.pageCurrent = num;
            try {
              let filter = '';
              if (
                this.queryFilter !== null
                && this.queryFilter !== ''
                && !this.queryFilter.includes('page=')
              ) {
                window.history.pushState(
                  '',
                  '',
                  `/${this.state.route}/list${this.queryFilter}&page=${this.pageCurrent}`,
                );
                filter = `${this.queryFilter}&page=${this.pageCurrent}`;
              } else {
                window.history.pushState('', '', `/${this.state.route}/list?page=${this.pageCurrent}`);
                filter = `?page=${this.pageCurrent}`;
              }

              const response = await this.defineListFunction()(filter);

              this.itensPerPage = response.headers['x-per-page'];
              const itensTotal = response.headers['x-total'];
              const aditionalPage = itensTotal % this.itensPerPage > 0 ? 1 : 0;

              this.totalPages = Math.floor(itensTotal / this.itensPerPage) + aditionalPage;

              this.pagination();

              this.setState({
                data: response.data,
              });
            } catch (err) {
              if (err.response) {
                alert(err.response.data.msg_errors[0].msg);
              } else if (err.request) {
                alert(err.request);
              } else {
                alert(err.message);
              }
            }
          }}
        >
          {num}
        </Pagination.Item>,
      );
    }
  }

  render() {
    return (
      <Fragment>
        <AppNavbar />

        <Row>
          <Col sm={2}>
            <Menu />
          </Col>
          <Col sm={10}>
            <Form ref={el => (this.myFormRef = el)} inline>
              <FormControl
                type="text"
                value={this.state.filterText}
                placeholder="service_id/nome"
                className="mr-sm-2"
                onChange={(e) => {
                  this.setState({
                    filterText: e.target.value,
                  });
                }}
              />

              <FormControl
                type="text"
                value={this.state.filterUrlPostGet}
                placeholder="url"
                className="mr-sm-2"
                onChange={(e) => {
                  this.setState({
                    filterUrlPostGet: e.target.value,
                  });
                }}
              />

              <FormGroup>
                <FormLabel>Tipo:</FormLabel>
                <FormControl
                  as="select"
                  className="mr-sm-2"
                  onChange={(e) => {
                    e.persist();
                    this.setState(prevState => ({
                      serviceType: { ...prevState.serviceType, type: e.target.value },
                    }));
                  }}
                  value={this.state.serviceType.type}
                >
                  <option />
                  {this.state.msTypes != null
                    ? this.state.msTypes.type.map((element, i) => (
                      <option key={i.toString()}>{element}</option>
                    ))
                    : ''}
                </FormControl>
              </FormGroup>

              <FormGroup>
                <FormLabel>Divisão: </FormLabel>
                <FormControl
                  as="select"
                  className="mr-sm-2"
                  onChange={(e) => {
                    e.persist();
                    this.setState(prevState => ({
                      serviceType: { ...prevState.serviceType, division: e.target.value },
                    }));
                  }}
                  value={this.state.serviceType.division}
                >
                  <option />
                  {this.state.msTypes != null
                    ? this.state.msTypes.division.map((element, i) => (
                      <option key={i.toString()}>{element}</option>
                    ))
                    : ''}
                </FormControl>
              </FormGroup>
              <FormGroup>
                <FormLabel>Fonte de Dados: </FormLabel>
                <FormControl
                  as="select"
                  className="mr-sm-2"
                  onChange={(e) => {
                    e.persist();
                    this.setState(prevState => ({
                      serviceType: { ...prevState.serviceType, source: e.target.value },
                    }));
                  }}
                  value={this.state.serviceType.source}
                >
                  <option />
                  {this.state.msTypes != null
                    ? this.state.msTypes.source.map((element, i) => (
                      <option key={i.toString()}>{element}</option>
                    ))
                    : ''}
                </FormControl>
              </FormGroup>
              <FormGroup>
                <FormLabel>Estado: </FormLabel>
                <FormControl
                  as="select"
                  className="mr-sm-2"
                  onChange={(e) => {
                    e.persist();
                    this.setState(prevState => ({
                      serviceType: { ...prevState.serviceType, state: e.target.value },
                    }));
                  }}
                  value={this.state.serviceType.state}
                >
                  <option />
                  {this.state.msTypes != null
                    ? this.state.msTypes.state.map((element, i) => (
                      <option key={i.toString()}>{element}</option>
                    ))
                    : ''}
                </FormControl>
              </FormGroup>
              <FormGroup>
                <FormControl
                  type="text"
                  value={this.state.serviceType.city}
                  placeholder="cidade"
                  className="mr-sm-2"
                  onChange={(e) => {
                    e.persist();
                    this.setState(prevState => ({
                      serviceType: { ...prevState.serviceType, city: e.target.value.toUpperCase() },
                    }));
                  }}
                />
              </FormGroup>

              <Col sm={3}>
                <FormLabel>Entidades: </FormLabel>
                <FormGroup>
                  {this.state.msTypes != null
                    ? this.state.msTypes.to_entity.map((element, i) => (
                      <Form.Check
                        key={i.toString()}
                        id={`checkbox${element}`}
                        htmlFor={`checkbox${element}`}
                        value={element}
                        type="checkbox"
                        checked={this.state.serviceType.to_entities.includes(element)}
                        label={element}
                        style={{ margin: '5px' }}
                        onChange={(e) => {
                          if (this.state.serviceType.to_entities.includes(e.target.value)) {
                            const toEntitiesCopy = [...this.state.serviceType.to_entities];
                            toEntitiesCopy.splice(
                              this.state.serviceType.to_entities.indexOf(e.target.value),
                              1,
                            );
                            this.setState(prevState => ({
                              serviceType: {
                                ...prevState.serviceType,
                                to_entities: toEntitiesCopy,
                              },
                            }));
                          } else {
                            this.setState(prevState => ({
                              serviceType: {
                                ...prevState.serviceType,
                                to_entities: [...prevState.serviceType.to_entities, element],
                              },
                            }));
                          }
                        }}
                      />
                    ))
                    : ''}
                </FormGroup>
              </Col>

              <Col sm={3}>
                <FormGroup>
                  <FormLabel>Ativado: </FormLabel>
                  <FormCheck
                    id="radioActivedTodos"
                    htmlFor="radioActivedTodos"
                    value="all"
                    type="radio"
                    checked={this.state.radioActived === 'all'}
                    label="Todos"
                    style={{ margin: '5px' }}
                    onChange={(e) => {
                      this.setState({
                        radioActived: e.target.value,
                      });
                    }}
                  />
                  <FormCheck
                    id="radioActivedSim"
                    htmlFor="radioActivedSim"
                    value="true"
                    type="radio"
                    checked={this.state.radioActived === 'true'}
                    label="Sim"
                    style={{ margin: '5px' }}
                    onChange={(e) => {
                      this.setState({
                        radioActived: e.target.value,
                      });
                    }}
                  />
                  <FormCheck
                    id="radioActivedNao"
                    htmlFor="radioActivedNao"
                    value="false"
                    type="radio"
                    checked={this.state.radioActived === 'false'}
                    label="Não"
                    style={{ margin: '5px' }}
                    onChange={(e) => {
                      this.setState({
                        radioActived: e.target.value,
                      });
                    }}
                  />
                </FormGroup>

                <FormGroup>
                  <FormLabel>Púlico: </FormLabel>
                  <FormCheck
                    id="radioShowPublicTodos"
                    htmlFor="radioShowPublicTodos"
                    value="all"
                    type="radio"
                    checked={this.state.radioShowPublic === 'all'}
                    label="Todos"
                    style={{ margin: '5px' }}
                    onChange={(e) => {
                      this.setState({
                        radioShowPublic: e.target.value,
                      });
                    }}
                  />
                  <FormCheck
                    id="radioShowPublicSim"
                    htmlFor="radioShowPublicSim"
                    value="true"
                    type="radio"
                    checked={this.state.radioShowPublic === 'true'}
                    label="Sim"
                    style={{ margin: '5px' }}
                    onChange={(e) => {
                      this.setState({
                        radioShowPublic: e.target.value,
                      });
                    }}
                  />
                  <FormCheck
                    id="radioShowPublicNao"
                    htmlFor="radioShowPublicNao"
                    value="false"
                    type="radio"
                    checked={this.state.radioShowPublic === 'false'}
                    label="Não"
                    style={{ margin: '5px' }}
                    onChange={(e) => {
                      this.setState({
                        radioShowPublic: e.target.value,
                      });
                    }}
                  />
                </FormGroup>
              </Col>

              <Button
                style={{ margin: '5px' }}
                variant="outline-warning"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    radioActived: 'all',
                    radioShowPublic: 'all',
                    serviceType: {
                      type: '',
                      division: '',
                      source: '',
                      state: '',
                      city: '',
                      to_entities: [],
                    },
                    filterText: '',
                    filterUrlPostGet: '',
                  });
                  this.resetFilters();
                  this.queryFilter = null;
                  window.history.pushState('', '', `/${this.state.route}/list`);
                }}
              >
                Reset
              </Button>
              <Button
                style={{ margin: '5px' }}
                variant="outline-success"
                onClick={(e) => {
                  e.preventDefault();
                  this.onSearchServicesClick();
                }}
              >
                Search
              </Button>
            </Form>
            <Table responsive="sm" striped bordered hover className="table-list">
              <thead>
                <tr>
                  <th>#</th>
                  <th>service_id</th>
                  <th>Nome</th>
                  <th>Tempo (segs)</th>
                  <th>Ativado</th>
                  <th>Público</th>
                  <th>Criado em</th>
                  <th>{this.isSync ? 'url' : 'url POST / url GET'}</th>
                  <th>Tipo</th>
                  <th>Editar</th>
                  <th>Deletar</th>
                </tr>
              </thead>
              <tbody>
                {this.state.data.map((item, i) => {
                  const index = i + 1 + (this.pageCurrent - 1) * this.itensPerPage;
                  return (
                    <tr key={index}>
                      <td>{index}</td>
                      <td>{item.mid}</td>
                      <td>{item.name}</td>
                      <td>{item.response_time}</td>
                      {
                        item.actived
                          ? <td>✓</td>
                          : <td style={{ backgroundColor: '#fe8e8e' }}>N</td>
                      }
                      {
                        item.show_public
                          ? <td>✓</td>
                          : <td style={{ backgroundColor: '#c8c8c8' }}>N</td>
                      }
                      <td>{moment(item.created_at).format('DD/MM/YYYY, HH:mm:ss')}</td>
                      { this.isSync ? (
                        <td>
                          {item.url}
                        </td>
                      ) : (
                        <td>
                          {item.url_post}
                          <br />
                          <hr />
                          {item.url_get}
                        </td>
                      )}
                      <td>
                        <ServiceType serviceType={item.service_type} />
                      </td>
                      <td>
                        <ButtonLink
                          href={`/${this.state.route}/edit/${item.mid}`}
                          variant="primary"
                          size="sm"
                          text="Editar"
                        />
                      </td>
                      <td>
                        <Button
                          onClick={() => window.confirm(`Tem certeza que deseja deletar o ${item.mid}`)
                            && this.deleteItem(item.mid)
                          }
                          variant="danger"
                          size="sm"
                        >
                          Deletar
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination>{this.paginationItems}</Pagination>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
